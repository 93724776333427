import {ChangeDetectorRef, Component} from '@angular/core';
import {Manufacturer, ManufacturerFoundIn, ManufacturerResponse} from "../../api/model/manufacturer";
import {ManufacturerService} from "../../api/manufacturer.service";
import {MatDialog} from "@angular/material/dialog";
import {
  ManufacturerEditDialogComponent,
  ManufacturerEditDialogData
} from "./manufacturer-edit-dialog/manufacturer-edit-dialog.component";
import {ActivatedRoute, Router, UrlTree} from "@angular/router";

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.sass']
})
export class ManufacturerComponent {
  displayedColumns: string[] = ['name', 'warrantyInMonths', 'hasSerialNumberByDefault', 'foundIn', 'responsible', 'actions'];
  manufacturers: ManufacturerResponse[] = [];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private manufacturerService: ManufacturerService) {
    this.loadManufacturers()
  }

  edit(name: string) {
    let manufacturer = this.manufacturers.find(value => value.name == name);
    const dialogData: ManufacturerEditDialogData = {
      name: name,
      warrantyInMonths: manufacturer?.assigned?.warrantyInMonths || null,
      hasSerialNumberByDefault: manufacturer?.assigned?.hasSerialNumberByDefault ?? null,
      responsible: manufacturer?.assigned?.responsible || [],
    }
    const dialogRef = this.dialog.open(ManufacturerEditDialogComponent, {data: dialogData, width: "500px"});

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.loadManufacturers()
    });
  }

  delete(manufacturer: Manufacturer) {
    if (confirm(`Are you sure you want to remove responsible people for manufacturer ${manufacturer.name}?`)) {
      this.manufacturerService.delete(manufacturer.id).subscribe(
        _ => this.loadManufacturers()
      )
    }
  }

  urlForOccurences(name: String, foundIn: ManufacturerFoundIn): UrlTree | undefined {
    if (foundIn == ManufacturerFoundIn.EQUIPMENT) {
      const tree = this.router.parseUrl("/equipment");
      tree.queryParams = {q: name};
      return tree;
    } else if (foundIn == ManufacturerFoundIn.CATALOG) {
      const tree = this.router.parseUrl("/settings/equipment-catalog");
      tree.queryParams = {q: name};
      return tree;
    } else {
      return undefined;
    }
  }

  private loadManufacturers() {
    this.manufacturerService.listDetailed().subscribe(response => {
      if (this.manufacturers.length == 0) {
        // first load
        const fragment = this.route.snapshot.fragment;
        if (fragment && fragment?.length > 0) {
          document.getElementById(fragment)?.scrollIntoView({behavior: "smooth"});
        }
      }
      this.manufacturers = response.sort((a, b) => a.assigned !== null && b.assigned === null ? 1 : 0);
      this._changeDetectorRef.markForCheck();
    });
  }
}
