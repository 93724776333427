import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {makeReadable} from "../../common/readable.pipe";

@Component({
  selector: 'app-check-box-filter',
  templateUrl: './check-box-filter.component.html',
  styleUrls: ['./check-box-filter.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckBoxFilterComponent {
  @Input() options!: CheckBoxOption[];
  @Input() selected!: string[];
  @Input() user: boolean = false;
  @Output() change = new EventEmitter<string[]>();

  checkboxChanged(option: string) {
    const pos = this.selected.indexOf(option);
    if (pos === -1) {
      this.selected.push(option)
    } else if (pos >= 0) {
      this.selected.splice(pos, 1);
    }
    this.emit();
  }

  clear() {
    this.selected = [];
    this.emit();
  }

  private emit() {
    this.change.emit(this.selected);
  }
}

export interface CheckBoxFilter {
  key: string;
  display: string;
  remember: boolean;
  options: CheckBoxOption[];
  selected: string[];
}

export interface CheckBoxOption {
  display: string;
  value: string;
}

export function option(value: string): CheckBoxOption {
  return {
    display: makeReadable(value),
    value
  };
}
