import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RmaService} from "../../api/rma.service";
import {distinctUntilChanged, EMPTY, merge, Observable, startWith, switchMap} from "rxjs";

import {apiErrorToMessage} from "../../common/util";
import {Rma} from "../../api/model/rma";
import _, {create} from "lodash";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {EquipmentCatalog} from "../../api/model/equipment-catalog";
import {debounceTime, map} from "rxjs/operators";
import {EquipmentCatalogService} from "../../api/equipment-catalog.service";
import {Equipment, EquipmentListResponse, Item} from "../../api/model/equipment";
import {EquipmentService} from "../../api/equipment.service";

@Component({
  selector: 'app-create-rma-dialog',
  templateUrl: './create-rma-dialog.component.html',
  styleUrls: ['./create-rma-dialog.component.sass']
})
export class CreateRmaDialogComponent {
  apiError: string | undefined;
  allEquipment: EquipmentCatalog[] = [];
  filteredEquipment: Observable<EquipmentCatalog[]>;
  filteredSerialNumbers: Observable<Equipment[]>;
  lastResponse: EquipmentListResponse | undefined;

  createForm = new FormGroup({
    rmaNumber: new FormControl(""),
    serialNumber: new FormControl("", [Validators.required]),
    manufacturer: new FormControl("", [Validators.required]),
    sku: new FormControl("", [Validators.required]),
    model: new FormControl("", [Validators.required]),
    hyperlink: new FormControl(""),
    description: new FormControl("", [Validators.required]),
    clientName: new FormControl("", [Validators.required]),
  })
  protected readonly create = create;

  constructor(
    private rmaService: RmaService,
    private equipmentService: EquipmentService,
    private equipmentCatalogService: EquipmentCatalogService,
    public dialogRef: MatDialogRef<CreateRmaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateRmaDialogData,
  ) {
    dialogRef.disableClose = true;
    this.filteredEquipment = merge(this.createForm.controls.sku.valueChanges, this.createForm.controls.model.valueChanges)
      .pipe(
        startWith(''),
        map(state => (state ? this._filterEquipment(state).slice(0, 20) : [])),
      );
    this.filteredSerialNumbers = this.createForm.controls.serialNumber.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          return this._filterSerialNumbers(val || '')
        })
      );
    equipmentCatalogService.list().subscribe((eqs) => this.allEquipment = eqs);
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.createForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onSubmit() {
    this.apiError = undefined;

    const formData = this.createForm.getRawValue();
    const request = formData as Rma;
    request.statuses = [];
    request.loanedItems = [];

    this.rmaService.create(request).subscribe({
      next: (rma) => {
        console.log("CreateRmaDialog onSubmit created", rma);
        this.dialogRef.close(rma)
      },
      error: error => {
        console.log("CreateRmaDialog onSubmit error", error);
        this.apiError = apiErrorToMessage(error);
        return EMPTY
      }
    });
  }

  projectTitle(id: string): string {
    const found = this.lastResponse?.projects.find(p => p.id == id);
    return found ? `${found.name}` : '';
  }

  matchingSerialNumbers(eq: Equipment): string {
    const query = this.createForm.controls.serialNumber.value || '';
    return eq.items.filter(item => item.serialNumber.includes(query))
      .map(value => value.serialNumber)
      .join(', ')
  }

  skuAutocompleteSelect($event: MatAutocompleteSelectedEvent) {
    const found = this.allEquipment.find(value => value.id == $event.option.id)
    if (found) {
      this.createForm.controls.sku.setValue(found.sku);
      this.createForm.controls.model.setValue(found.model);
      this.createForm.controls.manufacturer.setValue(found.manufacturer);
      this.createForm.controls.hyperlink.setValue(found.hyperlink);
    }
  }

  serialNumberAutocompleteSelect($event: MatAutocompleteSelectedEvent) {
    const found = this.lastResponse?.equipment.find(eq => eq.id == $event.option.id);
    if (found) {
      this.createForm.controls.sku.setValue(found.sku);
      this.createForm.controls.model.setValue(found.model);
      this.createForm.controls.manufacturer.setValue(found.manufacturer);
      this.createForm.controls.hyperlink.setValue(found.hyperlink);
    }
  }

  private _filterEquipment(filter: string): EquipmentCatalog[] {
    const f = filter.toLowerCase();
    return this.allEquipment.filter(equipment =>
      equipment.sku.toLowerCase().includes(f) || equipment.model.toLowerCase().includes(f)
    );
  }

  private _filterSerialNumbers(filter: string): Observable<Equipment[]> {
    return filter.length === 0 ? EMPTY :
      this.equipmentService.findBySerialNumber(filter)
        .pipe(map(value => {
          this.lastResponse = value;
          return value.equipment.flatMap(eq =>
            eq.items
              .filter(item => item.serialNumber.includes(filter))
              .map(item => {
                const cloned = _.cloneDeep(eq)
                cloned.items = [{serialNumber: item.serialNumber} as Item];
                return cloned;
              }))
        }));
  }
}

export interface CreateRmaDialogData {

}
