import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EMPTY, Observable} from "rxjs";

import {MatSnackBar} from "@angular/material/snack-bar";
import {UserCreateUpdateRequest, UserDetailed, UserInfo, UserRole, UserShortResponse} from "../../../api/model/user";
import {AuthService} from "../../../api/auth.service";
import {ComponentCanDeactivate} from "../../../pending-changes.guard";
import {apiErrorToMessage} from "../../../common/util";
import {Project, PROJECT_ROLE_DESCRIPTIONS, ProjectRole} from "../../../api/model/project";

@Component({
  selector: 'app-user-add-edit-dialog',
  templateUrl: './user-add-edit-dialog.component.html',
  styleUrls: ['./user-add-edit-dialog.component.sass']
})
export class UserAddEditDialogComponent implements ComponentCanDeactivate {
  userRoles: string[] = Object.values(UserRole);
  currentUser!: UserInfo | null;
  verb = this.data.user ? "Change" : "Invite";
  addEditForm = new FormGroup({
    email: new FormControl({value: this.data.user?.email, disabled: !!(this.data.user)}, [Validators.required]),
    name: new FormControl(this.data.user?.name, [Validators.required]),
    roles: new FormControl(this.data.user?.roles, []),
  })
  apiError: string | undefined;
  hasResetPassword: boolean = false;
  userDetailed: UserDetailed | undefined;
  projectRoleDescriptions: { [key: string]: string } = PROJECT_ROLE_DESCRIPTIONS;

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<UserAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserAddEditDialogData,
    private _snackBar: MatSnackBar,
  ) {
    authService.getCurrentUser().subscribe(u => this.currentUser = u);
    dialogRef.disableClose = true;
    if (data.user) {
      authService.getUserDetailed(data.user?.id).subscribe({
        next: (user) => {
          this.userDetailed = user;
        },
        error: error => {
          console.log("UserAddEditDialogComponent failed to load user details", error);
          this.apiError = apiErrorToMessage(error);
          return EMPTY;
        }
      })
    }
  }

  get userPermissions(): { project: Project, roles: ProjectRole[] }[] | undefined {
    if (this.userDetailed) {
      return this.userDetailed.permissions.map(p => {
        return {
          project: p,
          roles: p.permissions.filter(perm => perm.users.includes(this.userDetailed!!.user.email))
            .map(perm => perm.role),
        };
      })
    }
    return
  }

  isEditingCurrentUser(): boolean {
    return !!(this.data?.user) && this.currentUser?.id === this.data?.user?.id;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.addEditForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onSubmit() {
    this.apiError = undefined;
    const formData = this.addEditForm.getRawValue();
    const request = formData as UserCreateUpdateRequest;

    (this.data.user ? this.authService.editUser(this.data.user.id, request)
      : this.authService.createUser(request))
      .subscribe({
        next: (user) => {
          console.log("UserAddEditDialogComponent created user", user);
          this.dialogRef.close(user);
        },
        error: error => {
          console.log("UserAddEditDialogComponent failed to add/edit", error)
          this.apiError = apiErrorToMessage(error);
          return EMPTY;
        }
      });
  }

  resetPassword() {
    this.authService.requestPasswordReset(this.data.user!.email).subscribe(
      {
        next: (user) => {
          console.log("UserAddEditDialogComponent requested password reset", user);
          this._snackBar.open(`Password reset email sent to ${user.email}.`,
            undefined, {duration: 10000});
          this.hasResetPassword = true;
        },
        error: error => {
          console.log("UserAddEditDialogComponent failed to request password reset", error)
          this.apiError = apiErrorToMessage(error);
          return EMPTY;
        }
      }
    )
  }
}

export interface UserAddEditDialogData {
  user: UserShortResponse | undefined;
}
