<h1 mat-dialog-title>
  <app-draggable-dialog-handle/>
  <span>Assign <strong>{{ data.equipment.length }}</strong> items to sub-groups</span>
</h1>

<div class="dense-minimum" mat-dialog-content>

  <app-equipment-condense-table [equipment]="data.equipment"></app-equipment-condense-table>

  <div class="group-checkboxes">
    <ng-template #recursiveGroups let-groups>
      <ul>
        @for (group of groups; track group; let i = $index) {
          <li>
            <mat-checkbox class="example-margin" [checked]="isChecked(group)" [indeterminate]="isIndeterminate(group)"
                          (change)="update($event)" [value]="group.id" [disabled]="group.parentId == undefined"
                          [class.mat-primary]="selection[group.id] !== undefined"
                          [class.dirty]="selection[group.id] !== undefined">
              @if (group.parentId == undefined) {
                {{ group.accountingId }}
              }
              {{ group.nameWithoutParents }}
            </mat-checkbox>
            <ng-container *ngTemplateOutlet="recursiveGroups; context:{ $implicit: group.children }"></ng-container>
          </li>
        }
      </ul>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveGroups; context:{$implicit: projects}"></ng-container>
  </div>

</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="dirty ? 'warn' : ''" mat-button>Cancel</button>
  <button (click)="onPrimaryClick()" [disabled]="!dirty" color="primary" mat-raised-button>Assign</button>
  <span class="hint">Only changed (green) checkboxes will be saved.</span>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
