<h1 mat-dialog-title>
  <app-draggable-dialog-handle/>
  Snoozing alerts
</h1>

<div class="dense-1" mat-dialog-content>
  <h5>This snoozes selected alerts for all users until selected date.</h5>

  <!--  <app-equipment-condense-table [equipment]="data.equipment"></app-equipment-condense-table>-->

  <form (ngSubmit)="onSubmit()" [formGroup]="snoozeForm" class="snooze-form">
    <mat-form-field appearance="fill" class="no-bottom snooze-until date">
      <mat-label>Snooze until</mat-label>
      <!--(bsValueChange)="datePickerValueChange($event, 'purchaseOrderDate')"-->
      <input
        #snoozeUntilDate="bsDatepicker"
        (bsValueChange)="datePickerValueChange($event,'snoozeUntil')"
        bsDatepicker
        formControlName="snoozeUntil"
        matInput>
      <mat-datepicker-toggle (click)="snoozeUntilDate.toggle()"
                             [class.mat-datepicker-toggle-active]="snoozeUntilDate.isOpen"
                             matIconSuffix></mat-datepicker-toggle>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="snoozeForm.dirty ? 'warn' : ''"
          mat-button>Cancel
  </button>
  <button (click)="onPrimaryClick()" [disabled]="!snoozeForm.valid" color="primary" mat-raised-button>
    Snooze
  </button>
  <button (click)="onDeleteClick()" [hidden]="!data.alert.snoozedUntil" mat-raised-button>
    Remove Snooze
  </button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
