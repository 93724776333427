<h1 mat-dialog-title>
  <app-draggable-dialog-handle/>
  Editing {{ data.name }} manufacturer
</h1>

<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" class="edit-manufacturer-form">
    <div>
      <mat-form-field class="name" disabled="">
        <mat-label>Manufacturer name</mat-label>
        <input [value]="data.name" disabled matInput type="text">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="warranty">
        <mat-label>Warranty In Months</mat-label>
        <input [formControl]="warrantyInMonthsCtrl" matInput type="text">
      </mat-form-field>
    </div>

    <div>
      <mat-slide-toggle [formControl]="hasSerialNumberByDefault" class="hasSerialNumberByDefault"
                        labelPosition="before">Has Serial Number by default
      </mat-slide-toggle>
    </div>

    <div>
      <app-user-field [control]="responsibleCtrl"></app-user-field>
    </div>

  </form>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" mat-button>Cancel</button>
  <button (click)="onPrimaryClick()"
          [disabled]="isSubmitDisabled()"
          color="primary"
          mat-raised-button>Save
  </button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
