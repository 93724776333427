import {ActivatedRouteSnapshot, ResolveFn, Router} from "@angular/router";
import {Project} from "../api/model/project";
import {inject} from "@angular/core";
import {ProjectService} from "../api/project.service";
import {catchError, EMPTY, mergeMap, of} from "rxjs";
import {apiErrorToMessage} from "../common/util";
import {MatSnackBar} from "@angular/material/snack-bar";

export const projectResolver: ResolveFn<Project> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const projectService = inject(ProjectService);
  const _snackBar = inject(MatSnackBar);
  const id = route.paramMap.get('projectId')!;

  return projectService.get(id).pipe(mergeMap(project => {
    if (project) {
      console.log("projectResolver", project)
      return of(project);
    } else {  // id not found
      router.navigate(['/']);
      return EMPTY;
    }
  }), catchError(err => {
    const msg = apiErrorToMessage(err);
    _snackBar.open(`Could not load project. ${msg}`, "okay... :(");
    router.navigate(['/']);
    return EMPTY;
  }));
};
