import {Component, HostListener, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {EquipmentStatus} from "../../api/model/equipment";
import {HttpErrorResponse} from "@angular/common/http";
import {EMPTY, Observable} from "rxjs";
import {EquipmentService} from "../../api/equipment.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiError} from "../../api/model/common";

import {MatSnackBar} from "@angular/material/snack-bar";
import {AlertService} from "../../api/alert.service";
import {FormControl, FormGroup} from "@angular/forms";
import {AlertGrouped, AlertSnoozeRequest} from "../../api/model/alert";
import moment from "moment/moment";

@Component({
  selector: 'app-snooze-alert-dialog',
  templateUrl: './snooze-alert-dialog.component.html',
  styleUrls: ['./snooze-alert-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class SnoozeAlertDialogComponent implements OnInit {
  apiError: string | undefined;
  statuses: string[] = Object.values(EquipmentStatus);

  snoozeForm = new FormGroup({
    snoozeUntil: new FormControl<Date | null>(this.data.alert.snoozedUntil && new Date(this.data.alert.snoozedUntil), {
      // validators: [Validators.required],
      // updateOn: 'blur'
    }),
  });

  constructor(
    private alertService: AlertService,
    private equipmentService: EquipmentService,
    public dialogRef: MatDialogRef<SnoozeAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SnoozeAlertDialogData,
    private _snackBar: MatSnackBar,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.snoozeForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onDeleteClick() {
    this.snoozeForm.controls.snoozeUntil.setValue(null);
    this.snoozeForm.controls.snoozeUntil.markAsDirty();
    this.onSubmit();
  }

  datePickerValueChange(value: Date, formControlChange: string) {
    if (value && (value.getHours() != 0 || value.getMinutes() != 42 || value.getSeconds() != 0)) {
      const changed = moment(value).set({hour: 0, minute: 42, second: 0}).toDate()
      const formControl = this.snoozeForm.get(formControlChange);
      formControl?.setValue(changed);
      console.log(`SnoozeAlertDialogComponent date picker set hours, minutes and seconds to 0`, value, changed, formControl);
    }
  }

  onSubmit() {
    this.apiError = undefined;

    if (this.snoozeForm.controls.snoozeUntil.dirty && this.snoozeForm.controls.snoozeUntil.valid) {

      const request = {} as AlertSnoozeRequest;
      const id = (this.data.alert as any).id
      request.alertIds = id ? [id] : this.data.alert.alertIds;
      request.snoozedUntil = this.snoozeForm.value.snoozeUntil?.valueOf() || null;
      this.alertService.snooze(request).subscribe({
        next: (res) => {
          console.log(`SnoozeAlertDialogComponent snooze success`, request);
          this.dialogRef.close(true);
        },
        error: error => {
          console.log(`SnoozeAlertDialogComponent snooze failed`, request, error)
          this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
          return EMPTY;
        }
      });
    }
  }

}

export interface SnoozeAlertDialogData {
  alert: AlertGrouped;
}
