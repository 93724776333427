import {Component, EventEmitter, Input, Output, Signal, ViewChild, ViewEncapsulation} from '@angular/core';
import {Project, ProjectStatus} from "../../api/model/project";
import {AlertGrouped} from "../../api/model/alert";
import {
  ProjectAddEditDialogComponent,
  ProjectAddEditDialogData
} from "../add-edit-dialog/project-add-edit-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ProjectService} from "../../api/project.service";
import {AddChildDialogComponent} from "../add-child-dialog/add-child-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {projectUrl} from "../url.pipe";
import {MatSnackBar} from "@angular/material/snack-bar";
import {apiErrorToMessage} from "../../common/util";
import {PermissionsDialogComponent} from "../permissions-dialog/permissions-dialog.component";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-project-list-item',
  templateUrl: './project-list-item.component.html',
  styleUrls: ['./project-list-item.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectListItemComponent {
  @Input() project!: Project;
  @Input() alerts!: Signal<AlertGrouped[]>;
  @Output() updated = new EventEmitter<Project>();
  @Input() showDeadline: boolean = false;
  @Input() editable: boolean = true;
  statuses: string[] = Object.values(ProjectStatus);
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  constructor(public dialog: MatDialog,
              private projectService: ProjectService,
              private _snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private router: Router,
  ) {
  }

  editProject(project: Project) {
    const dialogData: ProjectAddEditDialogData = {
      project: project,
      type: project.type,
      nextAccountingId: undefined, previousAccountingId: undefined,
    };
    const dialogRef = this.dialog.open(ProjectAddEditDialogComponent, {
      maxHeight: "90vh",
      maxWidth: "1000px",
      width: "100%",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.projectService.get(project.id).subscribe(p => {
            this.project = p;
            console.log("ProjectListItemComponent project refetched after dialog close", p);
            this.updated.emit(p);
          },
          error => {
            // most likely project not found as it was deleted
            const p = this.project;
            console.log("ProjectListItemComponent project detected as DELETED", this.router.url, p.id, p.parentId, error);
            if (this.router.url.includes(p.id)) {
              if (p.parentId) {
                this.projectService.get(p.parentId).subscribe(parent => {
                  this.router.navigate([projectUrl(parent)]);
                  this.updated.emit(p);
                });
              } else {
                this.router.navigate(["/"]);
              }
            }
          },
        );
    });
  }

  toggleProjectBookmark(project: Project) {
    project.bookmarked = !project.bookmarked;
    this.projectService.setBookmark(project.id, project.bookmarked)
      .subscribe(value => {
        console.log("ProjectListItemComponent bookmark updated: projectId, value", project.id, project.bookmarked);
      })
  }

  addChild(project: Project) {
    const dialogRef = this.dialog.open(AddChildDialogComponent, {
      data: {parent: project},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("ProjectListItemComponent project child added", result);
        this.updated.emit(result);
      }
    });
  }

  editPermissions(project: Project): void {
    const dialogRef = this.dialog.open(PermissionsDialogComponent, {
      data: {project: project},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("ProjectListItemComponent project permissions updated", result);
        this.updated.emit(result);
      }
    });
  }

  changeStatus(project: Project, status: string, callback: () => void) {
    this.projectService.updateStatus(project.id, {status: status as ProjectStatus})
      .subscribe({
        next: value => {
          console.log("ProjectListItemComponent status changed: projectId, status", project.id, status);
          this.updated.emit(value);
        },
        error: err => {
          const msg = apiErrorToMessage(err);
          this._snackBar.open(`Could not change project status. ${msg}`, "okay... :(");
        }
      });
    callback();
  }

  menuCloseCallback(): () => void {
    return () => {
      this.trigger.closeMenu();
    };
  }
}
