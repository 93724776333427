import {AfterViewInit, Directive, ElementRef} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

/**
 * sourced from
 * https://www.reddit.com/r/Angular2/comments/9e93hc/comment/e5qy2w5/
 */
@Directive({
  selector: '[smoothScroll]'
})
export class SmoothScrollDirective implements AfterViewInit {
  constructor(private el: ElementRef, private route: ActivatedRoute) {
  }

  ngAfterViewInit() {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment && this.el.nativeElement && this.el.nativeElement.id === fragment) {
        /* --- browser check --- */
        this.el.nativeElement.scrollIntoView({behavior: "smooth"});
        /* --- if no smooth scroll --- */
        // javascript animation
      }
    });
  }

  /* Browser check method */
}
