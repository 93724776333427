<h1 mat-dialog-title>
  <app-draggable-dialog-handle/>
  Create RMA
</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" [formGroup]="createForm" class="create-rma-form">
    <div>
      <mat-form-field class="rma-number">
        <mat-label>RMA Number (autogenerated)</mat-label>
        <input formControlName="rmaNumber" matInput placeholder="Ex. SON-01544" type="text">
        <mat-error *ngIf="createForm.controls.rmaNumber.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="serial-number">
        <mat-label>Serial Number</mat-label>
        <input [matAutocomplete]="serialNumAuto" formControlName="serialNumber" matInput placeholder="Ex. 10001563324"
               type="text">
        <mat-error *ngIf="createForm.controls.serialNumber.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-autocomplete #serialNumAuto="matAutocomplete" (optionSelected)="serialNumberAutocompleteSelect($event)"
                        class="equipment-catalog-autocomplete" panelWidth="440px">
        <mat-option *ngFor="let eq of filteredSerialNumbers | async" [id]="eq.id" [value]="eq.items[0].serialNumber">
          <strong>{{ projectTitle(eq.projectId) }} </strong>
          <span>{{ eq.manufacturer }} <small>sku</small> {{ eq.sku }} <small>model</small> {{ eq.model }}
            <small>serial number</small> {{ matchingSerialNumbers(eq) }}</span>
        </mat-option>
      </mat-autocomplete>
    </div>

    <div>
      <mat-form-field class="manufacturer">
        <mat-label>Manufacturer</mat-label>
        <input formControlName="manufacturer" matInput placeholder="Ex. Meyer Sound" type="text">
        <mat-error *ngIf="createForm.controls.manufacturer.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="sku">
        <mat-label>SKU</mat-label>
        <input [matAutocomplete]="catalogAuto" formControlName="sku" matInput placeholder="Ex. 09.287.001.17"
               type="text">
        <mat-error *ngIf="createForm.controls.sku.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="model">
        <mat-label>Model</mat-label>
        <input [matAutocomplete]="catalogAuto" formControlName="model" matInput placeholder="Ex. ULTRA-X40" type="text">
        <mat-error *ngIf="createForm.controls.model.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <mat-autocomplete #catalogAuto="matAutocomplete" (optionSelected)="skuAutocompleteSelect($event)"
                      class="equipment-catalog-autocomplete" panelWidth="440px">
      <mat-option *ngFor="let eq of filteredEquipment | async" [id]="eq.id" [value]="eq.sku">
        <span>{{ eq.manufacturer }} <small>sku</small> {{ eq.sku }} <small>model</small> {{ eq.model }}</span>
      </mat-option>
    </mat-autocomplete>

    <div>
      <mat-form-field class="client-name">
        <mat-label>Client Name</mat-label>
        <input formControlName="clientName" matInput placeholder="Ex. LNDT" type="text">
        <mat-error *ngIf="createForm.controls.clientName.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="description">
        <mat-label>Problem Description</mat-label>
        <textarea cdkAutosizeMaxRows="18" cdkAutosizeMinRows="1"
                  cdkTextareaAutosize
                  formControlName="description"
                  matInput></textarea>
        <mat-error *ngIf="createForm.controls.description.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="createForm.dirty ? 'warn' : ''"
          mat-button>
    Cancel
  </button>
  <button (click)="onPrimaryClick()" [disabled]="!createForm.valid" color="primary" mat-raised-button>
    Create
  </button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
