import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {Equipment, EquipmentAssignRequest, EquipmentStatus} from "../../api/model/equipment";
import {EMPTY, Observable} from "rxjs";
import {EquipmentService} from "../../api/equipment.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Project} from "../../api/model/project";
import {apiErrorToMessage, transformToProjectsWithChildren} from "../../common/util";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: 'app-assign-dialog',
  templateUrl: './assign-dialog.component.html',
  styleUrls: ['../add-edit-dialog/equipment-add-edit-dialog.component.sass', '../bulk-edit-dialog/bulk-edit-dialog.component.sass', './assign-dialog.component.sass'],
})
export class AssignDialogComponent implements OnInit, AfterViewInit {
  apiError: string | undefined;
  statuses: string[] = Object.values(EquipmentStatus);
  projects: Project[] = [];
  dirty = false;
  selection: { [key: string]: boolean } = {};
  @ViewChild("filter") filterField!: ElementRef;

  constructor(
    private equipmentService: EquipmentService,
    public dialogRef: MatDialogRef<AssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssignDialogData,
  ) {
    dialogRef.disableClose = true;

    this.projects = transformToProjectsWithChildren(data.projects);
    console.log("AssignDialogComponent init", this.projects);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onSubmit() {
    this.apiError = undefined;

    if (this.dirty) {
      console.log(`AssignDialogComponent submitting form`, this.selection);

      const request: EquipmentAssignRequest = {
        ids: this.data.equipment.map(e => e.id),
        selection: this.selection,
      };
      this.equipmentService.assign(request)
        .subscribe({
          next: (res) => {
            console.log(`AssignDialogComponent assign success`, res);
            this.dialogRef.close(true);
          },
          error: error => {
            console.log(`AssignDialogComponent assign failed`, request, error)
            this.apiError = apiErrorToMessage(error);
            return EMPTY;
          }
        });
    }
  }

  isChecked(project: Project): boolean {
    const equipmentAssignedToProject = this.data.equipment.filter(e => e.assignedProjectIds.includes(project.id));
    return project.parentId == undefined || this.data.equipment.length == equipmentAssignedToProject.length;
  }

  isIndeterminate(project: Project): boolean {
    const equipmentAssignedToProject = this.data.equipment.filter(e => e.assignedProjectIds.includes(project.id));
    return equipmentAssignedToProject.length > 0 && equipmentAssignedToProject.length < this.data.equipment.length;
  }

  update($event: MatCheckboxChange) {
    this.dirty = true;
    this.selection[$event.source.value] = $event.checked;
  }
}

export interface AssignDialogData {
  projects: Project[];
  equipment: Equipment[];
}
