import {Activity} from "./activity";
import {Equipment, EquipmentCreateUpdateRequest} from "./equipment";
import {Task} from "./task";
import {Authored} from "./common";
import {Comment} from "./comment";

export enum ProjectStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  COMPLETE = "COMPLETE",
}

export interface Project {
  id: string;
  parentId?: string;
  topMostParentId: string;
  name: string;
  nameWithoutParents: string;
  accountingId: string;
  type: ProjectType;
  status: ProjectStatus;
  details: ProjectDetails;
  permissions: ProjectPermission[];
  created: Authored;
  updated: Authored;
  sort: number;

  tasks: Task[];
  warnings: string[];
  equipment: Equipment[];
  parents: Project[];
  comments: Comment[];
  activity: Activity[];
  children: Project[];
  hierarchyLevel: number;
  bookmarked: boolean;
}

export enum ProjectType {
  PUBLIC = "PUBLIC",
  PRODUCTION = "PRODUCTION",
  SALES = "SALES",
}

export function typeToPrefix(type: ProjectType): string {
  return type == ProjectType.SALES ? "S" : (type == ProjectType.PRODUCTION ? "G" : "");
}

export function removePrefix(prefix: string | undefined, accountingId: string | undefined): string | undefined {
  if (accountingId) {
    if (prefix && accountingId.startsWith(prefix)) {
      return accountingId.replace(prefix, "")
    }
  }
  return accountingId;
}

export interface ProjectDetails {
  deadline?: number;
  clientPreferredLanguage?: string;
  description?: string;
  location: {
    address?: string;
    mapsUrl?: string;
  };
  dropboxProjectLink?: string;
  projectCompletedDate?: number;
}

export interface ProjectPermission {
  role: ProjectRole;
  users: string[];
}

export enum ProjectRole {
  ADMIN = "ADMIN",
  READ = "READ",
  ADD_CHILD = "ADD_CHILD",
  EQUIPMENT_COPY_MOVE_DELETE = "EQUIPMENT_COPY_MOVE_DELETE",
  EQUIPMENT_ADD_EDIT_MAIN_INFO = "EQUIPMENT_ADD_EDIT_MAIN_INFO",
  EQUIPMENT_EDIT_STATUS_DATES = "EQUIPMENT_EDIT_STATUS_DATES",
  EQUIPMENT_EDIT_NOTES_COMMENTS = "EQUIPMENT_EDIT_NOTES_COMMENTS",
  EQUIPMENT_EDIT_SERIAL_NUMBERS = "EQUIPMENT_EDIT_SERIAL_NUMBERS",
  NOTIFICATIONS = "NOTIFICATIONS",
  PROJECT_MANAGER = "PROJECT_MANAGER",
}

export const PROJECT_ROLE_DESCRIPTIONS: { [key: string]: string } = {
  ADMIN: "Administrate",
  READ: "View",
  ADD_CHILD: "Add new project groups",
  EQUIPMENT_COPY_MOVE_DELETE: "Equipment copy/move/delete",
  EQUIPMENT_ADD_EDIT_MAIN_INFO: "Equipment add, edit main info",
  EQUIPMENT_EDIT_STATUS_DATES: "Equipment edit status, dates, etc.",
  EQUIPMENT_EDIT_NOTES_COMMENTS: "Equipment edit notes, comment",
  EQUIPMENT_EDIT_SERIAL_NUMBERS: "Equipment edit serial numbers",
  NOTIFICATIONS: "Notification on status, PDI updates",
  PROJECT_MANAGER: "Role: Project manager",
};

export const ADMIN_OVERRIDES_ROLES = [
  ProjectRole.READ,
  ProjectRole.ADD_CHILD,
  ProjectRole.EQUIPMENT_COPY_MOVE_DELETE,
  ProjectRole.EQUIPMENT_ADD_EDIT_MAIN_INFO,
  ProjectRole.EQUIPMENT_EDIT_STATUS_DATES,
  ProjectRole.EQUIPMENT_EDIT_NOTES_COMMENTS,
  ProjectRole.EQUIPMENT_EDIT_SERIAL_NUMBERS,
];

export interface ProjectCreateUpdateRequest {
  name: string;
  accountingId: string;
  parentId: string | undefined;
}

export interface ProjectNameUpdateRequest {
  name: string;
  accountingId: string;
}

export interface ProjectStatusUpdateRequest {
  status: ProjectStatus;
}

export interface BookmarkUpdateRequest {
  bookmark: boolean;
}

export interface ProjectPermissionUpdateRequest {
  permissions: ProjectPermission[];
}

export interface ProjectSortingUpdateRequest {
  sorting: {
    projectId: string;
    sort: number;
  }[];
}

export interface ProjectImport {
  accountingId: string;
  name: string;
  status: ProjectStatus;
  details: ProjectDetails;
  equipment: EquipmentCreateUpdateRequest[];
}
