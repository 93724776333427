<h1 mat-dialog-title>
  <app-draggable-dialog-handle/>
  Adding group for {{ data.parent?.name }}
</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" [formGroup]="addEditForm" class="add-edit-project-form">

    <div class="name-and-accounting-id">
      <mat-form-field class="name">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder="Ex. Lėlė I Etapas" type="text">
        <mat-error *ngIf="addEditForm.controls.name.hasError('required')">
          field <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

  </form>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="addEditForm.dirty ? 'warn' : ''" mat-button>Cancel</button>
  <button (click)="onSubmit()" [disabled]="!addEditForm.valid" color="primary" mat-raised-button>Add</button>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
