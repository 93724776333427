import {Component, HostListener, Inject} from '@angular/core';
import {
  ADMIN_OVERRIDES_ROLES,
  Project,
  PROJECT_ROLE_DESCRIPTIONS,
  ProjectPermission,
  ProjectRole
} from "../../api/model/project";
import {EMPTY} from "rxjs";
import {ProjectService} from "../../api/project.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {apiErrorToMessage} from "../../common/util";
import {AuthService} from "../../api/auth.service";
import _ from "lodash";

@Component({
  selector: 'app-permissions-dialog',
  templateUrl: './permissions-dialog.component.html',
  styleUrls: ['../add-edit-dialog/project-add-edit-dialog.component.sass', './permissions-dialog.component.sass']
})
export class PermissionsDialogComponent {
  project: Project;
  apiError: string | undefined;
  projectRoles: ProjectRole[] = Object.values(ProjectRole);
  projectRoleDescriptions: { [key: string]: string } = PROJECT_ROLE_DESCRIPTIONS;
  table: TableRow[] = [];
  displayedColumns: string[] = ["user", ...this.projectRoles];
  protected readonly ProjectRole = ProjectRole;

  constructor(
    private authService: AuthService,
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<PermissionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ProjectPermissionsDialogData,
  ) {
    this.project = data.project;
    this.authService.getAllUsers().subscribe(users => {
      this.table = users.map(u => {
        return {
          user: u.email,
          roles: this.data.project.permissions
            .filter(p => p.users.includes(u.email))
            .map(p => p.role)
        };
      });
    });
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.apiError = undefined;

    const pivoted: { [key: string]: string[] } = {};
    this.table.forEach(v =>
      v.roles.forEach(r => {
        if (pivoted.hasOwnProperty(r)) {
          pivoted[r].push(v.user);
        } else {
          pivoted[r] = [v.user];
        }
      }));
    const request: ProjectPermission[] = Object.keys(pivoted).map(key => {
      return {role: key as ProjectRole, users: pivoted[key]};
    })
    this.projectService.updatePermissions(this.data.project?.id, {permissions: request}).subscribe({
      next: (project) => {
        console.log("PermissionsDialogComponent updated permissions", project);
      },
      error: error => {
        console.log("PermissionsDialogComponent failed", error)
        this.apiError = apiErrorToMessage(error);
        return EMPTY;
      },
      complete: () => {
        if (!this.apiError) {
          this.dialogRef.close(true);
        }
      }
    });
  }

  hasRole(row: TableRow, role: ProjectRole): boolean {
    return row.roles.includes(role) || (row.roles.includes(ProjectRole.ADMIN) && ADMIN_OVERRIDES_ROLES.includes(role));
  }

  setRole(row: TableRow, role: ProjectRole, checked: boolean) {
    if (checked && !row.roles.includes(role)) {
      row.roles.push(role);
    } else if (!checked && row.roles.includes(role)) {
      row.roles = _.without(row.roles, role);
    }
  }

  isRoleOverridden(row: TableRow, role: ProjectRole) {
    if (ADMIN_OVERRIDES_ROLES.includes(role)) {
      if (this.hasRole(row, ProjectRole.ADMIN)) {
        return true;
      }
    }
    return false;
  }
}

interface TableRow {
  user: string,
  roles: ProjectRole[],
}

export interface ProjectPermissionsDialogData {
  project: Project;
}
