import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  HostListener,
  OnInit,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./api/auth.service";
import {UserInfo} from "./api/model/user";
import {BuildDetailsService} from "./api/build.service";
import {Observable} from "rxjs";
import {AlertService} from "./api/alert.service";
import {AlertGrouped} from "./api/model/alert";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {ProjectType} from "./api/model/project";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'Sonus Space';

  alerts: Signal<AlertGrouped[]> = this.alertService.alertsGroupedByEquipmentSignal;
  alertsProjectBadge: Signal<string> = computed(() => {
    const count = this.alerts().filter(a => a.project.type == ProjectType.PUBLIC).length;
    return count > 0 ? count.toString() : "";
  });
  alertsProductionBadge: Signal<string> = computed(() => {
    const count = this.alerts().filter(a => a.project.type == ProjectType.PRODUCTION).length;
    return count > 0 ? count.toString() : "";
  });
  alertsSalesBadge: Signal<string> = computed(() => {
    const count = this.alerts().filter(a => a.project.type == ProjectType.SALES).length;
    return count > 0 ? count.toString() : "";
  });
  snoozedAlerts: Signal<AlertGrouped[]> = this.alertService.snoozedAlertsGroupedByEquipmentSignal;
  alertBadge: Signal<string> = computed(() => {
    return this.alerts().length > 0 ? this.alerts().length.toString() : "";
  });

  buildNumber: String = this.buildDetailsService.buildNumberAtCompile
  buildNumberNew$: Observable<String> = this.buildDetailsService.newBuildNumberDetected
  buildIsUpToDate$: Observable<boolean> = this.buildDetailsService.buildIsUpToDateSubject
  isLoggedIn: Signal<{ value: boolean } | null> = this.authService.isLoggedInSignal;
  portrait: boolean = false;
  searchVisible: boolean = true;
  links = [
    {path: '/project', label: 'Project', badge: this.alertsProjectBadge},
    {path: '/production', label: 'Production', badge: this.alertsProductionBadge},
    {path: '/sales', label: 'Sales', badge: this.alertsSalesBadge},
    {path: '/equipment', label: 'Equipment', badge: null},
    {path: '/service', label: 'Service', badge: null},
  ];

  constructor(private router: Router,
              private authService: AuthService,
              private responsive: BreakpointObserver,
              private alertService: AlertService,
              private buildDetailsService: BuildDetailsService,
              private _changeDetectorRef: ChangeDetectorRef,
  ) {
    effect(() => {
      console.log(`AppComponent alertsBadge = ${this.alertBadge}, alerts`, this.alerts());
    });

    effect(() => {
      console.log(`AppComponent authService loggedIn fired`, this.isLoggedIn());
      this._changeDetectorRef.markForCheck();
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    console.log('AppComponent popStateEvent triggerred', event);
    this._changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    this.responsive.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait,
    ])
      .subscribe(result => {
        this.searchVisible = true;
        this.portrait = false;
        if (result.matches) {
          this.searchVisible = false;
          this.portrait = true;
        }
      });
  }

  currentUser(): UserInfo | null | undefined {
    return this.authService.currentUser;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["/login"])
      .then(() => {
        window.location.reload();
      });
  }

  showSearch() {
    this.searchVisible = true;
  }
}
