import {Component, computed, Input, Signal} from '@angular/core';
import {AlertGrouped, AlertSeverity, isAlertForEquipment} from "../../api/model/alert";
import {Equipment} from "../../api/model/equipment";
import {makeDateReadable} from "../../common/readable.pipe";

@Component({
  selector: 'app-alert-icon',
  templateUrl: './alert-icon.component.html',
  styleUrls: ['./alert-icon.component.sass']
})
export class AlertIconComponent {
  @Input() alerts!: Signal<AlertGrouped[]>;
  @Input() smallIcon: boolean = false;
  @Input() equipment: Equipment | undefined;

  alertsFiltered: Signal<AlertGrouped[]> = computed(() => this.alerts().filter(a => !this.equipment || isAlertForEquipment(a, this.equipment)));
  critical: Signal<boolean> = computed(() => this.alertsFiltered().some(a => a.severity === AlertSeverity.CRITICAL));
  warning: Signal<boolean> = computed(() => this.alertsFiltered().some(a => a.severity === AlertSeverity.WARNING));
  snoozed: Signal<boolean> = computed(() => this.alertsFiltered().length > 0 && this.alertsFiltered().every(a => a.snoozedUntil != null));

  tooltip(): string {
    return this.alertsFiltered()
      .map(a => a.message
        + (!this.equipment ? ` (${a.culpritEquipmentIds ? a.culpritEquipmentIds.length : ""})` : "")
        + (a.snoozedUntil ? ", snoozed until " + makeDateReadable(a.snoozedUntil) : ""))
      .join("\n");
  }
}
