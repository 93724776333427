import {Component} from "@angular/core";

@Component({
  selector: 'app-draggable-dialog-handle',
  template: '<mat-icon class="handle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>drag_indicator</mat-icon>',
  styles: `
    :host
      display: inline-flex
      margin-right: 8px
      align-items: center

      .handle
        cursor: grab

        &.cdk-drag-dragging
          cursor: grabbing
  `,
})
export class DraggableDialogHandleComponent {

}
