import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {Equipment, EquipmentMoveToRequest, EquipmentStatus} from "../../api/model/equipment";
import {HttpErrorResponse} from "@angular/common/http";
import {EMPTY, fromEvent, Observable} from "rxjs";
import {EquipmentService} from "../../api/equipment.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiError} from "../../api/model/common";

import {MatSnackBar} from "@angular/material/snack-bar";
import {Project} from "../../api/model/project";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {debounceTime} from "rxjs/operators";
import {ProjectService} from "../../api/project.service";

@Component({
  selector: 'app-move-copy-dialog',
  templateUrl: './move-copy-dialog.component.html',
  styleUrls: ['../add-edit-dialog/equipment-add-edit-dialog.component.sass', '../bulk-edit-dialog/bulk-edit-dialog.component.sass', './move-copy-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class MoveCopyDialogComponent implements OnInit, AfterViewInit {
  apiError: string | undefined;
  statuses: string[] = Object.values(EquipmentStatus);
  filteredProjects: Project[] = [];
  initialProjects: Project[];
  selectedProject: Project | undefined;
  lastFilterValue: string = "";
  @ViewChild("filter") filterField!: ElementRef;

  constructor(
    private projectService: ProjectService,
    private equipmentService: EquipmentService,
    public dialogRef: MatDialogRef<MoveCopyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MoveCopyDialogData,
    private _snackBar: MatSnackBar,
  ) {
    dialogRef.disableClose = true;

    this.initialProjects = data.projects;
  }

  isMove(): boolean {
    return this.data.action == MoveCopyAssignDialogAction.MOVE;
  }

  isCopy(): boolean {
    return this.data.action == MoveCopyAssignDialogAction.COPY;
  }

  ngOnInit() {
    if (this.isCopy()) {
      this.projectService.list(undefined, this.initialProjects[0].accountingId).subscribe(
        p => this.filteredProjects = p
      );
    }
  }

  ngAfterViewInit() {
    const keyUpEvent = fromEvent(this.filterField.nativeElement, 'keyup')
    keyUpEvent.pipe(debounceTime(500)).subscribe(c => {
      this._filterProjects((c as any)?.target?.value)
    });
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.moveToForm.dirty;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onPrimaryClick() {
    this.onSubmit()
  }

  onSubmit() {
    this.apiError = undefined;

    if (this.moveToForm.controls.projectId.dirty && this.moveToForm.controls.projectId.valid && this.selectedProject) {
      const request = {} as EquipmentMoveToRequest;
      request.ids = this.data.equipment.map(e => e.id)
      request.projectId = this.selectedProject.id;
      (this.data.action == MoveCopyAssignDialogAction.MOVE
        ? this.equipmentService.moveTo(request)
        : this.equipmentService.copy(this.data.equipment, request.projectId))
        .subscribe({
          next: (eq) => {
            console.log(`dialog ${this.data.action} equipment success`, request);
            this.dialogRef.close(true);
          },
          error: error => {
            console.log(`dialog ${this.data.action} equipment failed`, request, error)
            this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
            return EMPTY;
          }
        });
    }
  }

  projectAutocompleteSelect($event: MatAutocompleteSelectedEvent) {
    this.selectedProject = $event.option.value;
    this.lastFilterValue = "" + this.selectedProject?.name
    this.moveToForm.controls.projectId.setValue(this.lastFilterValue);
  }

  private projectSelectionValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    console.log("projectSelectionValidator validation triggered", this.selectedProject);
    return !this.selectedProject ? {projectAutocompleted: {value: control.value}} : null;
  };

  moveToForm = new FormGroup({
    projectId: new FormControl('', {
      validators: [Validators.required, this.projectSelectionValidator],
      updateOn: 'blur'
    }),
  });

  private _filterProjects(filter: string | null | undefined) {
    if (!filter || filter.length == 0) {
      this.filteredProjects = this.initialProjects.slice();
      return;
    }
    if (this.lastFilterValue != filter) {
      this.selectedProject = undefined;
      const f = filter.toLowerCase();
      const filterTopMostProjectsOnly = this.isMove();
      this.projectService.list(filter, undefined, undefined, undefined, undefined, undefined, undefined, filterTopMostProjectsOnly)
        .subscribe(projects =>
          this.filteredProjects = projects
        );
    }
  }
}

export interface MoveCopyDialogData {
  projects: Project[];
  equipment: Equipment[];
  action: MoveCopyAssignDialogAction;
}

export enum MoveCopyAssignDialogAction {
  MOVE = "MOVE",
  COPY = "COPY",
}
