import {ActivatedRouteSnapshot, ResolveFn, Router} from "@angular/router";
import {Project} from "../api/model/project";
import {inject} from "@angular/core";
import {ProjectService} from "../api/project.service";
import {catchError, EMPTY, mergeMap, of} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {apiErrorToMessage} from "../common/util";

export const accountingIdResolver: ResolveFn<Project[]> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const projectService = inject(ProjectService);
  const accountingId = route.paramMap.get('accountingId')!;
  const _snackBar = inject(MatSnackBar);

  return projectService.list(undefined, accountingId).pipe(mergeMap(projects => {
    if (projects) {
      console.log("accountingIdResolver", projects);
      if (projects.length === 0) {
        throw Error("Incorrect URL or no access to project.");
      }
      return of(projects);
    } else {  // id not found
      router.navigate(['/']);
      return EMPTY;
    }
  }), catchError(err => {
    const msg = apiErrorToMessage(err);
    _snackBar.open(`Could not load project. ${msg}`, "okay... :(");
    router.navigate(['/']);
    throw Error(msg);
  }));
};
