import {Component, HostListener} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RmaService} from "../../api/rma.service";
import {LoanedItem, Rma, RmaStatus, RmaStatusType} from "../../api/model/rma";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {apiErrorToMessage} from "../../common/util";
import {EMPTY, merge, Observable, startWith} from "rxjs";
import _ from "lodash";
import {EquipmentCatalog} from "../../api/model/equipment-catalog";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map} from "rxjs/operators";
import {EquipmentCatalogService} from "../../api/equipment-catalog.service";
import {ProjectService} from "../../api/project.service";
import {EquipmentService} from "../../api/equipment.service";
import {Project} from "../../api/model/project";
import {Equipment} from "../../api/model/equipment";

@Component({
  selector: 'app-single-rma',
  templateUrl: './single-rma.component.html',
  styleUrls: ['./single-rma.component.sass']
})
export class SingleRmaComponent {
  rma!: Rma;
  editForm: FormGroup = this.fb.group({});
  rmaStatusTypes: string[] = Object.values(RmaStatusType);
  apiError: string | undefined;
  allEquipment: EquipmentCatalog[] = [];
  filteredEquipment: Observable<EquipmentCatalog[]> = EMPTY;
  relatedProject: Project | undefined;
  relatedEquipment: Equipment | undefined;
  projectFirmwareVersion: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private rmaService: RmaService,
    private equipmentCatalogService: EquipmentCatalogService,
    private projectService: ProjectService,
    private equipmentService: EquipmentService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
  ) {
    equipmentCatalogService.list().subscribe((eqs) => this.allEquipment = eqs);

    this.route.data.subscribe(data => {
      this.rma = data['rma'] as Rma;
      if (this.rma.projectId) {
        projectService.get(this.rma.projectId).subscribe(p => this.relatedProject = p)
        equipmentService.findBySerialNumber(this.rma.serialNumber).subscribe(r => {
          this.relatedEquipment = r.equipment[0];
          this.projectFirmwareVersion = this.relatedEquipment.items.find(i => i.serialNumber.includes(this.rma.serialNumber))?.firmwareVersion
        })
      }
      this.editForm = this.fb.group({
        rmaNumber: [{value: this.rma.rmaNumber, disabled: true}, [Validators.required]],
        serialNumber: [this.rma.serialNumber, [Validators.required]],
        manufacturer: [this.rma.manufacturer, [Validators.required]],
        sku: [this.rma.sku, [Validators.required]],
        model: [this.rma.model, [Validators.required]],
        hyperlink: [this.rma.hyperlink],
        description: [this.rma.description, [Validators.required]],
        clientName: [this.rma.clientName, [Validators.required]],
        firmwareVersion: [this.rma.firmwareVersion],
        invoiceNumber: [this.rma.invoiceNumber],
        invoiceDate: [this.rma.invoiceDate],
        installedDate: [this.rma.installedDate],
        warrantyUntil: [{value: this.rma.warrantyUntil, disabled: true}],
        statuses: this.fb.array(this.rma.statuses.map(s => this.generateStatusForm(s))),
        loanedItems: this.fb.array(this.rma.loanedItems.map(l => this.generateLoanedItemForm(l))),
      });

      this.filteredEquipment = merge(...this.loanedItems.controls.map(group => group.controls['sku'].valueChanges))
        .pipe(
          startWith(''),
          map(state => (state ? this._filterEquipment(state).slice(0, 20) : [])),
        );
    });
  }

  get statuses(): FormArray<FormGroup> {
    return this.editForm.controls['statuses'] as FormArray<FormGroup>;
  }

  get loanedItems(): FormArray<FormGroup> {
    return this.editForm.controls['loanedItems'] as FormArray<FormGroup>;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.editForm.dirty;
  }

  addStatus() {
    this.statuses.push(this.generateStatusForm());
    this.editForm.markAsDirty();
  }

  addLoanedItem() {
    this.loanedItems.push(this.generateLoanedItemForm());
    this.editForm.markAsDirty();
  }

  removeStatus(i: number) {
    if (confirm("Are you sure you want to remove this status?")) {
      this.statuses.removeAt(i);
      this.editForm.markAsDirty();
    }
  }

  removeLoanedItem(i: number) {
    if (confirm("Are you sure you want to remove this loaned item?")) {
      this.loanedItems.removeAt(i);
      this.editForm.markAsDirty();
    }
  }

  onSubmit() {
    this.apiError = undefined;

    const formData = this.editForm.getRawValue();
    const base = _.cloneDeep(this.rma);
    base.loanedItems = [];
    base.statuses = [];
    const request = _.merge(base, formData) as Rma;


    this.rmaService.update(this.rma.id, request).subscribe({
      next: (rma) => {
        console.log("SingleRmaComponent onSubmit updated", rma);
        this.editForm.markAsPristine();
        this.editForm.controls['warrantyUntil'].setValue(rma.warrantyUntil);
        this.apiError = undefined;
      },
      error: error => {
        console.log("SingleRmaComponent onSubmit error", error);
        this.apiError = apiErrorToMessage(error);
      }
    });
  }

  autocompleteSelect($event: MatAutocompleteSelectedEvent, form: FormGroup) {
    const found = this.allEquipment.find(value => value.id == $event.option.id)
    if (found) {
      form.controls['sku'].setValue(found.sku);
      form.controls['model'].setValue(found.model);
      form.controls['manufacturer'].setValue(found.manufacturer);
    }
  }

  totalTimeSpentInHours(): number {
    return this.statuses.controls.map(formGroup => formGroup.controls['timeSpentInHours'].value).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  private _filterEquipment(filter: string): EquipmentCatalog[] {
    const f = filter.toLowerCase();
    return this.allEquipment.filter(equipment =>
      equipment.sku.toLowerCase().includes(f) || equipment.model.toLowerCase().includes(f)
    );
  }

  private generateStatusForm(status?: RmaStatus): FormGroup {
    return this.fb.group({
      date: [status?.date, Validators.required],
      type: [status?.type, Validators.required],
      description: [status?.description],
      timeSpentInHours: [status?.timeSpentInHours],
    });
  }

  private generateLoanedItemForm(l?: LoanedItem): FormGroup {
    const formGroup = this.fb.group({
      serialNumber: [l?.serialNumber, Validators.required],
      manufacturer: [l?.manufacturer || this.rma.manufacturer, Validators.required],
      sku: [l?.sku || this.rma.sku, Validators.required],
      model: [l?.model || this.rma.model, Validators.required],
      dispatchDate: [l?.dispatchDate],
      returnedDate: [l?.returnedDate],
    });
    this.filteredEquipment = merge()
      .pipe(
        startWith(''),
        map(state => (state ? this._filterEquipment(state).slice(0, 20) : [])),
      );
    return formGroup;
  }
}
